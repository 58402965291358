import React, { useState } from "react";
import "./logerreur.css";  

export default function Logerreur() {
  const [searchErreur, setSearchErreur] = useState("");
  const [activeIndex, setIsActiveIndex] = useState(-1);
  const data = [
    {
      Date: "24/02/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"bahri","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value',
    },
    {
      Date: "23/08/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"test","value":{"struct":{"member":[{"name":"valid","v...',
    },
    {
      Date: "01/12/2021 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "14/07/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"firstname","value":{"struct":{"member":[{"name":"valid","v...',
    },
    {
      Date: "01/12/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "15/01/1919 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/02/2024 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/12/2023 17h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/12/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/01/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/10/2023 10h20",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/12/2022 12h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
    {
      Date: "01/12/2023 18h24",
      description:
        '[{"name":"result","value":{"struct":{"member":[{"name":"mtp","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"CORINNE ,ANGELE ,JEANNE"}},{"name":"expected","value":{"string":"Corinne"}}]}}},{"name":"birthdate","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"1"}},{"name":"found","value":{"string":"18/06/1975"}},{"name":"expected","value":{"string":"18/06/1975"}}]}}},{"name":"secondlastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"VIGNOLET"}},{"name":"expected","value":{"string":[]}}]}}},{"name":"lastname","value":{"struct":{"member":[{"name":"valid","value":{"boolean":"0"}},{"name":"found","value":{"string":"BUCHARD"}},{"name":"expected","value":{"string":"BUCHARD VIGNOLET"}}]}}}]}}},{"name":"reason","value":{"i4":"2"}},{"name":"reasonMessage","value":{"string":"Wrong information"}},{"name":"id","value":{"string":"7b93b550-b6f3-31ee-9c4f-3fb486bb2fdb"}},{"name":"status","value":{"i4":"2"}}]',
    },
  ];

  const dataFiltred = (data) => {
    return data.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchErreur.toLowerCase())
      )
    );
  };

  const handleColorChange = (index) => {
    if (index === activeIndex) {
      setIsActiveIndex(-1);
    } else {
      setIsActiveIndex(index);
    }
  };

  return (
    <div className="logerreur-content">
      <div className="home-text">
        <h2>Log Erreur</h2>
      </div>
      <div className="list-title">
        <p>Rechercher dans les logs</p>
        <input
          type="text"
          className="list-filter-input"
          placeholder="Nom, prénom, date..."
          value={searchErreur}
          onChange={(e) => setSearchErreur(e.target.value)}
        />
      </div>
      <div>
        <table className="table-logerreur">
          <tbody>
            {dataFiltred(data).map((current, index) => (
              <tr
                key={`row-index-${index}`}
                onClick={() => {
                  handleColorChange(index);
                }}
                className={index === activeIndex ? "showMore" : "tr-logerreur"}
              >
                <td className="td-logerreur">
                  <span>{current.Date}</span>{" "}
                </td>
                <td>
                  <div>{current.description}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
