import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../hooks/AuthContext";
import Loder from "../components/Loder";
export default function CheckPi() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { logout, token } = useContext(AuthContext);

  const columns = [
    { value: "dateCreation", label: "Date", with: "col-1" },
    { value: "gender", label: "Gender", with: "col-1" },
    { value: "nom", label: "Nom", with: "col-1" },
    { value: "prenom", label: "Prénom", with: "col-1" },
    { value: "email", label: "Email", with: "col-2" },
    { value: "telephone", label: "Téléphone", with: "col-2" },
    { value: "produit", label: "Tunnel", with: "col-3" },
    { value: "status", label: "État", with: "col-1" },
  ];

  // Function to handle sorting
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Helper function to filter the data based on the search term
  const filterData = (data) => {
    return data.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  // Sort the filtered data
  const sortedData = sortColumn
    ? filterData(data).sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
      return 0;
    })
    : filterData(data);

  // Get total number of pages
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Helper function to generate pagination buttons
  const generatePaginationButtons = () => {
    const visibleButtons = 5; // Number of visible buttons
    const totalButtons = Math.min(visibleButtons, totalPages);

    let startPage = Math.max(currentPage - Math.floor(totalButtons / 2), 1);
    const endPage = startPage + totalButtons - 1;

    if (endPage > totalPages) {
      startPage = Math.max(totalPages - totalButtons + 1, 1);
    }

    return Array.from(
      { length: totalButtons },
      (_, index) => startPage + index
    );
  };

  // Get current items for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Change items per page
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const navigate = useNavigate();

  const clickListe = (row, rowIndex) => {
    navigate(`/detailstunnel/${row.id}`, { state: { row, rowIndex } });
  };

  async function getListTunnel() {
 
    try {
      const response = await fetch(`${apiUrl}/tunnel`,
        {
          method: "POST",
          headers: {
            'x-forwarded-authorization': token,
          }
        });

      const data = await response.text();
      setData(JSON.parse(data));
      setIsLoading(false);
    } catch (error) {
      console.log(
        "There has been a problem with your fetch operation: ",
        error.message
      );
      logout()
    }
  }

  const statusColor = (value) => {
    if (value === 'valide') {
      return 'statusgreen'
    } else if (value === 'encours') {
      return 'statusorange'
    } else {
      return 'statuserror'
    }
  }


  useEffect(() => {
    setTimeout(() => {
      getListTunnel();
    }, 1000)
    setIsLoading(true);

  }, []);

  return (
    <div >
      <div className="home-text">
        <h2>Listing des tunnels</h2>
      </div>
      {isLoading ? (
        <div className="loder-content">
          <span><Loder /></span>
        </div>
      ) : (<>
        <div className="list-title">
          <p>Rechercher un tunnel</p>
          <input
            type="text"
            className="list-filter-input"
            placeholder="Nom, prénom, email, téléphone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table className="data-table">
          {/* Added className to table element */}
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} className={column.with} onClick={() => handleSort(column.value)}>
                  {column.label}{(column.label === "Date" && sortDirection == null) && <span>&#8595;</span>}
                  {sortColumn === column.value && (
                    <span>
                      {sortDirection === "asc" ? <>&#8593;</> : <>&#8595;</>}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            <>
              {currentItems.map((row, rowIndex) => (
                <tr key={rowIndex} onClick={() => clickListe(row, rowIndex)}>
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className={`data-cell ${column.with}`} >
                      <span className={statusColor(row[column.value])}
                      >
                        {row[column.value]}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          </tbody>

        </table>

        <div className="pagination-buttons">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="page-button"
          >
            Previous
          </button>
          {generatePaginationButtons().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              style={{
                fontWeight: page === currentPage ? "bold" : "normal",
              }}
              className="page-button" // Added className to button element
            >
              {page}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="page-button" // Added className to button element
          >
            Next
          </button>
          <label>
            Tunnel par page:
            <select
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}
              className="items-per-page-select" // Added className to select element
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </label>
        </div>
      </>)}
    </div>
  );
}
