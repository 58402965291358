import React, { useContext, useState } from "react";
import logo from "../images/logo.png";
import line from "../images/Line.svg";
import { NavLink, useLocation } from "react-router-dom";
import AuthContext from "../hooks/AuthContext.js";
import { FaBars, FaTimes } from "react-icons/fa";

export default function Header() {
  const { isLoggedIn, logout, user } = useContext(AuthContext);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let location = useLocation()

  return (
    <header className="header">
      <div className="content-header">
        <div className="nav-bar">
          <div className="content-logo">
            <NavLink to={isLoggedIn ? "home" : "/"}>
              {" "}
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          {isLoggedIn && (location.pathname !== "/") ? (
            <div className="navigation-menu">
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li>
                  <NavLink to="listetunnel" onClick={handleClick}>
                    Voir tunnel{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="checkpi" onClick={handleClick}>
                    Check PI
                  </NavLink>
                </li>
                <li>
                  <NavLink to="checkuniversign" onClick={handleClick}>
                    Check Universign
                  </NavLink>
                </li>
               {/*  <li onClick={handleClick}>
                  <NavLink to="logerreur">Log Erreur</NavLink>
                </li> */}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>

        {isLoggedIn && (location.pathname !== "/")? (
          <>
            <div className="logout">
              <div className="username">{JSON.parse(localStorage.getItem('username'))}</div>
              <div className="logout-logo" onClick={logout}>
                <img src={line} alt="Logout" title="Logout" />
              </div>
            </div>
            <div className="hamburger" onClick={handleClick}>
              {click ? (
                <FaTimes size={20} style={{ color: "#333" }} />
              ) : (
                <FaBars size={20} style={{ color: "#333" }} />
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
}
