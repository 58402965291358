import cloud from "../images/cloud.svg"
import iconPDF from "../images/iconPDF.svg"
import iconUniversing from "../images/iconUniversing.svg"
import iconEnvoieEmail from "../images/iconEnvoieEmail.svg"
import iconTesterPI from "../images/iconTesterPI.svg"
import iconCheckUniversign from "../images/iconCheckUniversign.svg"
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../hooks/AuthContext";

export default function CardTunnel({ handlerFunction, urlUniversign, urlMailFin, urlCreateDocs, idSF, idUniversign }) {
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState()
    const [checkpiData, setCheckpiData] = useState()
    const [checkUniversignLoading, setCheckUniversignLoading] = useState(false)



    const navigate = useNavigate();

    const salesforceLink = () => {
        window.open(`https://meilleurtaux-placement.lightning.force.com/lightning/r/Contact/${idSF}/view`)
    }

    const checkPiUniversign = async () => {
        setCheckUniversignLoading(true)
        try {
            // Appel API asynchrone
            const response = await fetch(`https://recette.souscrire-en-ligne.finance-selection.fr/visualisation/verification/${idUniversign}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'x-forwarded-authorization': token,
                    }
                }
            );
            const res = await response.json();
            setCheckpiData(res);
            setCheckUniversignLoading(false);
            navigate("/checkpi", { state: res, idSF })
        } catch (error) {
            console.error('Error fetching data:', error);
            setCheckUniversignLoading(false)
        }

    }

    const checkCertificatUniversign = async () => {

        try {
            // Appel API asynchrone
            const response = await fetch(`https://recette.souscrire-en-ligne.finance-selection.fr/visualisation/coordonnees/${idUniversign}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'x-forwarded-authorization': token,
                    }
                }
            );
            const res = await response.json();
            setDatas(res)
            // Naviguer vers la page des résultats après le fetch
            navigate("/checkuniversign", { state: res, idSF })  // Passe les données via l'état de l'historique
        } catch (error) {
            console.error('Error fetching data:', error);
        }


        // navigate("/checkuniversign")
    }

    const GenererLiassePdf = () => {

        fetch(urlCreateDocs, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-forwarded-authorization': token,
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.valide) {
                    window.location.reload()
                }
            })
            .catch((err) => console.log(err));
    }
    const universignLink = () => {
        window.open(urlUniversign)
    }
    const renvoyerMailFin = () => {
        setLoading(true)
        fetch(urlMailFin, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-forwarded-authorization': token,
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.valide) {
                    setLoading(false)
                    handlerFunction(true, "Sucess")

                } else {
                    setLoading(false)
                    handlerFunction(true, "danger")
                }

            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                handlerFunction(true, "danger")

            });
    }
    const dataTunnel = [
        {
            icon: cloud,
            textCard: 'Voir fiche Salesforce',
            cssCard: 'box-1',
            link: `https://meilleurtaux-placement--mobile.sandbox.my.salesforce.com/${idSF}`,
            onClick: salesforceLink
        },
        {
            icon: iconPDF,
            textCard: 'Générer liasse PDF',
            cssCard: 'box-1',
            link: urlCreateDocs,
            onClick: GenererLiassePdf
        },
        {
            icon: iconUniversing,
            textCard: 'Lien iframe Universign',
            cssCard: 'box-1',
            link: urlUniversign,
            onClick: universignLink
        },
        {
            icon: iconEnvoieEmail,
            textCard: loading ? "Envoi en cours..." : 'Renvoyer mail fin',
            cssCard: 'box-1',
            link: urlMailFin,
            onClick: renvoyerMailFin
        },
        {
            icon: iconTesterPI,
            textCard: checkUniversignLoading ? "Verification en cours..." :'Tester les PI',
            cssCard: 'box-1',
            link: "checkpi",
            onClick: checkPiUniversign
        },
        {
            icon: iconCheckUniversign,
            textCard: 'Checker certificat Universign',
            cssCard: 'box-1',
            link: 'checkCertificatUniversign',
            onClick: checkCertificatUniversign
        },
    ]



    return (
        <>

            {dataTunnel.map((value, values) =>
                <button key={values} disabled={!value.link} className={value.cssCard} onClick={() => value.onClick()}>
                    <img src={value.icon} alt="" />
                    <p className='text-box'>{value.textCard}</p>
                </button>
            )}
        </>
    )
}