import React from "react";
import listicon from "../images/listicon.svg";
import loupeicon from "../images/loupeicon.svg";
import usericon from "../images/usericon.svg";
import { NavLink } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <div className="home-text">
        <h2>Tu veux faire quoi ?</h2>
      </div>

      <div className="item-list">
        <NavLink to="/listetunnel">
          <div className="item">
            <div className="item-icon">
              <img src={listicon} width={100} alt="" />
            </div>
            <div className="item-text">
              <p>
                Voir / Gérer <br /> les tunnels
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink to="/checkpi">
          <div className="item">
            <div className="item-icon">
              <img src={loupeicon} width={100} alt="" />
            </div>
            <div className="item-text">
              <p>
                Tester une <br /> pièce d’identité
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink to="/checkuniversign">
          <div className="item">
            <div className="item-icon">
              <img src={usericon} width={100} alt="" />
            </div>
            <div className="item-text">
              <p>
                Checker une personne
                <br /> chez universign
              </p>
            </div>
          </div>
        </NavLink>
       {/*  <NavLink to="/logerreur">
          <div className="item">
            <div className="item-icon">
              <img src={erreuricon} alt="" />
            </div>
            <div className="item-text">
              <p>
                Voir les remonter <br /> d’erreurs
              </p>
            </div>
          </div>
        </NavLink> */}
      </div>
    </div>
  );
}
