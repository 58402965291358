import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(() => {
    // Initialize state from localStorage or provide default initial state
    const storedState = localStorage.getItem("logIn");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [user, setUser] = useState(() => {
    // Initialize state from localStorage or provide default initial state
    const storedState = localStorage.getItem("username");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [error, setError] = useState("");
  const [token, setToken] = useState(() => {
    // Initialize state from localStorage or provide default initial state
    const storedState = localStorage.getItem("token");
    return storedState ? JSON.parse(storedState) : false;
  });
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Store state in localStorage whenever it changes
    localStorage.setItem("logIn", JSON.stringify(isLoggedIn));
    localStorage.setItem("username", JSON.stringify(user));
    localStorage.setItem("token", JSON.stringify(token));
   
  }, [isLoggedIn, user,token]);

  async function login(username, password) {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);

    try {
      const response = await axios.post(`${apiUrl}/connexion`, data);

      if (response.status === 200 && response.data.valid) {
        setToken(response.data.token);
        setLoggedIn(true);
        setUser(username);
        navigate("/home");
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError("An error occurred while logging in");
    }
  }

  const logout = () => {
    setLoggedIn(false);
    setToken("")
    navigate("/");
    setError("");
    localStorage.removeItem("logIn")
    localStorage.clear("token")
    localStorage.removeItem("username")
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, user, error ,token,setLoggedIn}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
