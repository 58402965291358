import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import iconSupprimer from "../images/iconSupprimer.svg";
import AuthContext from "../hooks/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ModalSuppression({ document }) {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const url = document.urlSuppression;

    const supprimerTunnel = () => {
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-forwarded-authorization': token,
            }
        })
            .then((res) => res.json())
            .then((data) => {
                {
                    data === true ?
                    navigate("/listetunnel")


                    : alert("Erreur lors de la suppression du tunnel")

                }
            })
            .catch((err) => console.log(err));
    }

    const close = (e) => {


    }

    return (
        <>
            <div className="box">
                <button type="button" className="btn-supp" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                    <img src={iconSupprimer} alt={""} />
                    <p className="text-suppression-modal">
                        Suppression tunnel <br /> suite demande RGPD
                    </p>
                </button>
            </div>


            <div className="modal fade align-content-center modal-taille" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h1 className="modal-title fs-3 fw-bold text-center" id="exampleModalLabel">Voulez-vous vraiment
                                supprimer ce tunnel ? </h1>
                        </div>
                        <div className="modal-body text-center">
                            <p className="fw-semibold fs-5">Tunnel
                                produit {document.produit?.name} de<br />{document.dataPersonne.gender.abbr} {document.dataPersonne.nom} {document.dataPersonne.prenom}</p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="button-modal-close" data-bs-dismiss="modal" onClick={(e) => close(e)} >Fermer</button>
                            <button type="button" className="button-modal-supp" data-bs-dismiss="modal" onClick={() => supprimerTunnel()}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}