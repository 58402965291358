import React from "react";
import valideicon from "../images/validicon.svg";
import invalidicon from "../images/invalidicon.svg";
import trueicon from "../images/trueicon.svg";
import falseicon from "../images/falseicon.svg";

export default function Resultat({ dataResult, formData }) {
  return (
    <div className="result">
      <hr className="hr" />
      {dataResult.message == "Erreur technique" ?
       (<div className="result-content">
        <h2>{dataResult.message}</h2>
        <img src={invalidicon} alt="" />
        
      </div>) :
        (<div className="result-content">
          <h2>Résultat {dataResult.valide}</h2>
          <img src={dataResult.valide ? valideicon : invalidicon} alt="" />
          <p>
            La pièce d’identité est
            {dataResult.valide ? (
              <span> valide !</span>
            ) : (
              <span> invalide !</span>
            )}
          </p>
        </div>)}
      {!dataResult.valide && dataResult.message !== "Erreur technique" ? (
        <>
          <div className="retour-universign">
            <h2>Retour universign : </h2>
            <p>{dataResult.message}</p>
          </div>
          <div className="table-verif">
            <table id="table-check">
              <thead>
                <tr>
                  <th className="table-check-th" colSpan="2"></th>
                  <th className="table-check-th" rowSpan="2">
                    Data envoyé le form
                  </th>
                  <th className="table-check-th" rowSpan="2">
                    Data détecté par universign
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-check-tr">
                  <td className="table-check-td">
                    <img
                      src={
                        dataResult.erreurs.lastname?.valide == 1
                          ? trueicon
                          : falseicon
                      }
                      alt=""
                    />
                  </td>
                  <td className="table-check-td">Nom</td>
                  <td className="table-check-td">{formData.nom}</td>
                  <td className="table-check-td">
                    {dataResult.erreurs.lastname?.found}
                  </td>
                </tr>
                <tr className="table-check-tr">
                  <td className="table-check-td">
                    <img
                      src={
                        dataResult.erreurs.firstname?.valide == 1
                          ? trueicon
                          : falseicon
                      }
                      alt=""
                    />
                  </td>
                  <td className="table-check-td">Prénom</td>
                  <td className="table-check-td">{formData.prenom}</td>
                  <td className="table-check-td">
                    {dataResult.erreurs.firstname?.found}
                  </td>
                </tr>
                {formData.birthname && (
                  <tr className="table-check-tr">
                    <td className="table-check-td">
                      <img
                        src={
                          dataResult.erreurs.lastname?.valide == 1
                            ? trueicon
                            : falseicon
                        }
                        alt=""
                      />
                    </td>
                    <td className="table-check-td">Nom de naissance</td>
                    <td className="table-check-td">{formData.birthname}</td>
                    <td className="table-check-td">
                      {" "}
                      {/* {dataResult.erreurs.lastname.found} */}{" "}
                    </td>
                  </tr>
                )}
                <tr className="table-check-tr">
                  <td className="table-check-td">
                    <img
                      src={
                        dataResult.erreurs.birthdate?.valide === 1
                          ? trueicon
                          : falseicon
                      }
                      alt=""
                    />
                  </td>
                  <td className="table-check-td">Date de naissance</td>
                  <td className="table-check-td">
                    {dataResult.erreurs.birthdate?.expected}
                  </td>
                  <td className="table-check-td">
                    {dataResult.erreurs.birthdate?.found}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}


