import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ListeTunnel from "./pages/ListeTunnel";
import CheckPi from "./pages/CheckPi";
import CheckUniversign from "./pages/CheckUniversign";
import Header from "./components/Header";
import AuthProvider from "./hooks/AuthProvider";
import Logerreur from "./pages/Logerreur";
import DetailsTunnel from "./pages/DetailsTunnel";
import PrivateRoutes from "./components/PrivateRoutes";
function App() {
  return (
    <div className="app">
      <Router>
          <AuthProvider>
            <Header />
            <div className="main-container">
              <Routes>
                  <Route exact path="/"  element={<Login />} />
                  <Route element={<PrivateRoutes />}>
                  <Route path="/home" element={<Home />}  />
                  <Route path="/listetunnel" element={<ListeTunnel />} />
                  <Route path="/checkpi" element={<CheckPi />} />
                  <Route path="/checkuniversign" element={<CheckUniversign />} />
                  <Route path="/logerreur" element={<Logerreur />} />
                  <Route path="/detailstunnel/:id" element={<DetailsTunnel />} />
                </Route>
              </Routes>
            </div>
          </AuthProvider>
        </Router>
    </div>
  );
}

export default App;
