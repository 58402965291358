import React, { useContext, useEffect, useState } from "react";
import Resultat from "../components/Resultat";
import AuthContext from "../hooks/AuthContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

export default function CheckPi() {
  const location = useLocation()
  const [selectedOption, setSelectedOption] = useState("");
  const [dataResult, setDataResult] = useState(location?.state?.result ? location?.state?.result : null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: "cni" || location?.state?.datas?.type,
    nom: "" || location?.state?.datas?.nom,
    prenom: "" || location?.state?.datas?.prenom,
    birthname: "" || location?.state?.datas?.birthname,
    birthdate: "" || location?.state?.datas?.birthdate,
    recto: null,
    verso: null,
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const { token, setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const options = [
    { value: "passeport", label: "Passeport" },
    { value: "carte-de-sejour", label: "Carte de sejour" },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, type: event.target.value });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.files[0],
    });
  };

  const deleteVersoFile = () => {
    setFormData({
      ...formData,
      verso: null
    });
  };

  const deleteRectoFile = () => {
    setFormData({
      ...formData,
      recto: null,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.prenom) {
      newErrors.prenom = "Champs obligatoire!";
      isValid = false;
    }

    if (!formData.nom) {
      newErrors.nom = "Champs obligatoire!";
      isValid = false;
    }
    if (!formData.recto) {
      newErrors.recto = "Champs obligatoire!";
      isValid = false;
    }
    if (!formData.verso && formData.type !== "passeport") {
      newErrors.verso = "Champs obligatoire!";
      isValid = false;
    }
    if (!formData.birthdate) {
      newErrors.birthdate = "Champs obligatoire!";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const dragStart = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    const date = new Date(formData.birthdate);
    let dateDMY = date.toLocaleDateString("en-GB");
    let data = new FormData();
    data.append("type", formData.type);
    data.append("nom", formData.nom);
    data.append("prenom", formData.prenom);
    data.append("birthname", formData.birthname);
    data.append("birthdate", dateDMY);
    data.append("recto", formData.recto);
    data.append("verso", formData.verso);

    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/verification`, data,
          { headers: { 'x-forwarded-authorization': token, "content-type": "multipart/form-data" } }
        );

        if (response.status === 200) {
          window.scrollTo(0, document.body.scrollHeight);
          setDataResult(response.data);
          setLoading(false);
        } else {
          console.log("Error:", response.status);
        }
      }
      catch (error) {
        console.log("Error:", error);
        navigate("/");
        setLoggedIn(false);
      }
    }
    else {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (dataResult) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [dataResult]);
  return (
    <div >
      <div className="home-text">
        <h2>Tester une pièce d’identité </h2>
      </div>
      <div className="container-checkpi">
        <form onSubmit={handleFormSubmit}>
          <div className="type-pi">
            <p>Type de justificatif d’identité</p>
            <select
              className="select-pi"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option className="option-select" value="cni">
                Carte d'identité
              </option>
              {options.map((option) => (
                <option
                  className="option-select"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="file-upload-container">
            {selectedOption === "passeport" ? (
              <div className="dropzone" onDragStart={(e) => dragStart(e)} draggable>
                {!formData.recto ? <i className="fa fa-folder"></i> : <i className="fa fa-folder blue"></i>}
                {!formData.recto ?
                  <div className="nofile">
                    <p>Glissez-déposez le fichier<br />(jpeg, jpg, png, gif, tif, pdf)</p>
                  </div> :
                  <div className="fileok">
                    <div className="name-file">
                      <span className="balisename">{formData.recto.name}</span></div>
                    <div><button onClick={(e) => { deleteRectoFile(e, null) }} className="delete-file"><i className="deletefile fa fa-times-circle"></i></button></div>

                  </div>}
                <p className="fake-cta"> {!formData.recto ? "Télécharger le recto" : "Changez le fichier"}</p>
                <input id="recto" onChange={handleFileChange} name="recto" className="file-input" type="file" required />

              </div>
            ) : (
              <>
                <div className="dropzone" onDragStart={(e) => dragStart(e)} draggable >
                  {!formData.recto ? <i className="fa fa-folder"></i> : <i className="fa fa-folder blue"></i>}
                  {!formData.recto ?
                    <div className="nofile">
                      <p>Glissez-déposez le fichier<br />(jpeg, jpg, png, gif, tif, pdf)</p>
                    </div> :
                    <div className="fileok">
                      <div className="name-file">
                        <span className="balisename">{formData.recto.name}</span></div>
                      <div><button name="recto" onClick={(e) => { deleteRectoFile(e, null) }} className="delete-file"><i className="deletefile fa fa-times-circle"></i></button></div>

                    </div>}
                  <p className="fake-cta"> {!formData.recto ? "Télécharger le recto" : "Changez le fichier"}</p>
                  <input id="recto" onChange={handleFileChange} name="recto" className="file-input" type="file" required />

                </div>

                <div className="dropzone" onDragStart={(e) => dragStart(e)} draggable>
                  {!formData.verso ? <i className="fa fa-folder"></i> : <i className="fa fa-folder blue"></i>}
                  {!formData.verso ?
                    <div className="nofile">
                      <p>Glissez-déposez le fichier<br />(jpeg, jpg, png, gif, tif, pdf)</p>
                    </div> :
                    <div className="fileok">
                      <div className="name-file">
                        <span className="balisename">{formData.verso.name}</span></div>
                      <div>   <button name="verso" onClick={(e) => { deleteVersoFile() }} className="delete-file"><i className="deletefile fa fa-times-circle"></i></button></div>

                    </div>}
                  <p className="fake-cta"> {!formData.verso ? "Télécharger le verso" : "Changez le fichier"}</p>
                  <input id="verso" onChange={handleFileChange} name="verso" className="file-input" type="file" required />
                </div>
              </>
            )}
          </div>

          <div className="form-input">
            <div className="input-filter-group">
              <p>Prénom</p>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formData.prenom}
                onChange={handleInputChange}
                className={errors.prenom && !formData.prenom ? "invalid-input" : "list-filter-input"}
                placeholder="Prénom"
              />
              {errors.prenom && !formData.prenom ? <div className="error">{errors.prenom}</div> : null}
            </div>
            <div className="input-filter-group">
              <p>Nom</p>
              <input
                type="text"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleInputChange}
                className={errors.nom && !formData.nom ? "invalid-input" : "list-filter-input"}
                placeholder="Nom"
              />
              {errors.nom && !formData.nom ? <div className="error">{errors.nom}</div> : null}
            </div>
            <div className="input-filter-group">
              <p>Nom de naissance</p>
              <input
                type="text"
                id="birthname"
                name="birthname"
                onChange={handleInputChange}
                className="list-filter-input"
                placeholder="Nom de naissance"
              />
            </div>
            <div className="input-filter-group">
              <p>Date de naissance</p>
              <input
                type="date"
                name="birthdate"
                id="birthdate"
                value={formData.birthdate}
                onChange={handleInputChange}
                className={errors.birthdate && !formData.birthdate ? "invalid-input" : "list-filter-input"}
                placeholder="Date de naissance"
                selected={new Date()}
              />
              {errors.birthdate && !formData.birthdate ? <div className="error">{errors.birthdate}</div> : null}
            </div>
          </div>
          <div></div>
          <div className="btn-validation">
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button type="submit" className="validate-btn">
                Valider
              </button>
            )}
          </div>
        </form></div>

      {dataResult && <Resultat dataResult={dataResult} formData={formData} />}
    </div>
  );
}
