import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DeposeDoc from "../components/DeposeDoc";
import ModalSuppression from "../components/ModalSuppression";
import CardTunnel from "../components/CardTunnel";
import "./detailTunnel.css";
import AuthContext from "../hooks/AuthContext";
import Loder from "../components/Loder";

export default function DetailsTunnel() {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [infodata, setinfoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mail, setMail] = useState(false);
  const location = useLocation();
  const document = location.state.row;
  const index = location.state.rowIndex;
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [postData] = useState({ key: "value" });
  const [alertmessage, setAlertMessage] = useState("");

  useEffect(() => {
    const getDetailTunnel = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/tunnel/${id}`,
          {
            method: "POST",
            headers: {
              "x-forwarded-authorization": token,
            },
          }
        );
        const result = await response.text();
        setinfoData(JSON.parse(result));
        setIsLoading(false);
      } catch (error) {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      }
    };
    getDetailTunnel();
  }, [postData]);

  const handlerFunction = (mail, alertmessage) => {
    setMail(mail);
    setAlertMessage(alertmessage);
  };
  const statusColor = (value) => {
    if (value === 'valide') {
      return 'statusgreen-details'
    } else if (value === 'encours') {
      return 'statusorange-details'
    } else {
      return 'statuserror-details'
    }
  }
  return (
    <div className="detail-tunnel">
      {isLoading ? (
        <div className="loder-content">
          <span>
            <Loder />
          </span>
        </div>
      ) : (
        <>
          <div className="home-text-">
            <h3>
              <b>
                Tunnel {infodata.produit?.name}
                <br />
                de {infodata.dataPersonne.gender.abbr}{" "}
                {infodata.dataPersonne.nom} {infodata.dataPersonne.prenom}
              </b>
            </h3>
          </div>
          <div>
            <span
              className={statusColor(document.status)}
            >
              {" "}
              {document.status}
            </span>
          </div>

          <div className="home-avancement-details">
            <h3>
              Étape {infodata.etape} : {infodata.avancement}
            </h3>
          </div>

          <div className="home-text-details">
            <p className="donnee-header">
              ID Personne BDD: <b>{infodata.dataPersonne.id ?? ("Pas d'ID BDD renseigner")}</b>
              <br />
              ID Tunnel SF: <b>{infodata.id ?? ("Pas d'ID SF renseigner")}</b>
              <br />
              Date de creation: <b>{infodata.dateCreation ?? ("Pas de date de création renseigner")}</b>
              <br />
              Email: <b>{infodata.dataPersonne.email ?? ("Pas d'email renseigner")}</b>
              <br />
              Téléphone: <b>{infodata.dataPersonne.mobile ?? ("Pas de numéro renseigner") }</b>
            </p>
          </div>

          {mail && alertmessage === "Sucess" && (
            <div
              className="alert alert-info alert-dismissible fade show container col-3"
              role="alert"
            >
              <strong>Mail envoyé </strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => {
                  setMail(false);
                }}
              ></button>
            </div>
          )}
          {mail && alertmessage === "danger" && (
            <div
              className="alert alert-danger alert-dismissible fade show container col-4"
              role="alert"
            >
              <strong>Mail n'est pas envoyé </strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => {
                  setMail(false);
                }}
              ></button>
            </div>
          )}

          <div className="box">
            <CardTunnel
              urlSalesforce={infodata.urlSalesforce}
              urlUniversign={infodata.urlUniversign}
              urlMailFin={infodata.urlMailFin}
              urlCreateDocs={infodata.urlCreateDocs}
              idSF={infodata.dataPersonne.idSF}
              idUniversign={infodata.id}
              handlerFunction={handlerFunction}
            />
            <div>
              <ModalSuppression document={infodata}/>
            </div>
          </div>
          {Object.keys(infodata.justificatifs).length ? (
            <>
              <div>
                <DeposeDoc documents={infodata} index={index} />
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
