import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../hooks/AuthContext";


const PrivateRoutes = () => {
   const  {isLoggedIn}  = useContext(AuthContext); 
  /* const isLoggedIn = JSON.parse(localStorage.getItem("logIn")) */

  
  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
