import React, { useContext, useEffect, useState } from "react"
import TableauUniversign from "../components/TableauUniversign"
import './checkUniversign.css'
import AuthContext from "../hooks/AuthContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";


export default function CheckUniversign() {
    const location = useLocation()
    const [loading, setLoading] = useState(false);
    const [retour, setRetour] = useState(false);
    const [dataResult, setDataResult] = useState(location?.state?.result ? location?.state?.result : []);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        nom: "" || location?.state?.datas?.nom,
        prenom: "" || location?.state?.datas?.prenom,
        mobile: "" || location?.state?.datas?.mobile,
        email: "" || location?.state?.datas?.email
    });


    const navigate = useNavigate();

    const { token, setLoggedIn } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.prenom) {
            newErrors.prenom = "Champs obligatoire!";
            isValid = false;
        }

        if (!formData.nom) {
            newErrors.nom = "Champs obligatoire!";
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = "Champs obligatoire!";
            isValid = false;
        }

        if (!formData.mobile) {
            newErrors.mobile = "Champs obligatoire!";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    async function handleFormSubmit(e) {
        e.preventDefault();
        let data = new FormData();
        data.append("nom", formData.nom);
        data.append("prenom", formData.prenom);
        data.append("mobile", formData.mobile);
        data.append("email", formData.email);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiUrl}/coordonnees`,
            headers: {
                'x-forwarded-authorization': token,
            },
            data: data
        };

        if (validateForm()) {
            setLoading(true);
            try {
                const response = await axios.request(config)
                if (response.status === 200) {
                    window.scrollTo(0, document.body.scrollHeight);
                    setDataResult(response.data.result)
                    setLoading(false);
                    setRetour(true)
                }
            }
            catch (error) {
                console.log("Error:", error);
                navigate("/");
                setLoggedIn(false);
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }

    }
    useEffect(() => {
        if (dataResult) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [dataResult]);
    return (
        <div >
            <div className="home-text">
                <h2>Checker data Universign </h2>
            </div>
            <div className="container-checkUniversign">
                <form onSubmit={handleFormSubmit}>
                    <div className="form-input">
                        <div className="input-block">
                            <p>Prénom</p>
                            <input
                                type="text"
                                id="prenom"
                                name="prenom"
                                value={formData.prenom}
                                onChange={handleInputChange}
                                className={errors.prenom && !formData.prenom ? "invalid-input" : "list-check-input"}
                                placeholder="Prénom"
                            />
                        </div>

                        <div className="input-block">
                            <p>Nom</p>
                            <input
                                type="text"
                                id="nom"
                                name="nom"
                                value={formData.nom}
                                onChange={handleInputChange}
                                className={errors.nom && !formData.nom ? "invalid-input" : "list-check-input"}
                                placeholder="Nom"
                            />
                        </div>
                        <div className="input-block">
                            <p>Email</p>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className={errors.email && !formData.email ? "invalid-input" : "list-check-input"}
                                placeholder="Email"
                            />
                        </div>
                        <div className="input-block">
                            <p>Téléphone</p>
                            <input
                                type="text"
                                name="mobile"
                                id="mobile"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                className={errors.mobile && !formData.mobile ? "invalid-input" : "list-check-input"}
                                placeholder="Téléphone"
                            />
                        </div>
                    </div>
                    <div className="btn-validation-check-data">
                        {loading ? (
                            <button className="btn btn-primary" type="button" disabled>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </button>
                        ) : (
                            <button type="submit" className="validate-btn" >
                                Valider
                            </button>
                        )}
                    </div>
                </form></div>
               
            {(retour || location?.state) &&
                <div style={{ margin: "auto" }}>
                    {dataResult.length ?
                        <table className="data-table">

                            <thead>
                                <tr> <th>certificat</th>
                                    <th>certificatLevel</th>
                                    <th>emailRetour</th>
                                    <th>nomRetour</th>
                                    <th>prenomRetour</th>
                                    <th>telephoneRetour</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataResult.map((item, index) => (
                                    <tr key={index}><td>{item.certificat}</td>
                                        <td>{item.certificatLevel}</td>
                                        <td>{item.emailRetour}</td>
                                        <td>{item.nomRetour}</td>
                                        <td>{item.prenomRetour}</td>
                                        <td>{item.telephoneRetour}</td>

                                    </tr>
                                ))}


                            </tbody>
                        </table> : <p className="no-data-found">No data found</p>}
                </div> }


            {/*  {dataResult && <TableauUniversign formData={formData} dataResult={dataResult} />} */}
        </div>
    );
}
