import React, { useContext, useState } from "react";
import AuthContext from "../hooks/AuthContext.js";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, error } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  return (
    <div className="wrapper1">
      <div className="logincontainer">
        <div className="home-text">
          <h2>Connexion</h2>
          <p className="login-text">
            Vous êtes sur un site moyennement sécurisé qui vous <br /> garantit
            pas grand chose.
          </p>
        </div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>Login </label>
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-container">
              <label>Mot de passe </label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-container">
              <input type="submit" value="Continuer" />
            </div>
            {error && (
              <div
                className="error"
                style={{ color: "red", textAlign: "center" }}
              >
                Invalid Login or Password
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
