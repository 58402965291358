import React from 'react'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import iconeBouttonDoc from "../images/iconeBouttonDoc.svg";
import downloadicon from "../images/downloadicon.svg";
import reddownloadicon from "../images/reddownloadicon.svg";
import { useContext } from 'react';
import AuthContext from '../hooks/AuthContext';


export default function DeposeDoc({ documents }) {
  const { token } = useContext(AuthContext);

  const DownLoadZipJustificatifs = () => {
    const zip = new JSZip();
    const folder = zip.folder();
    //Add files to folder
    {
      Object.keys(documents.justificatifs).forEach((e) => {
        const blob = fetch(documents.justificatifs[e].url,
          {
            method: 'POST',
            headers: { 'x-forwarded-authorization': token }
          }).then((res) => res.blob());
        folder.file(documents.justificatifs[e].name, blob, { binary: true });
      });
    }
    //Zip folder and download folderZip
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "ZipFiles");
    });
  };

  const DownloadJustificatif = async (index) => {
    try {
      const response = await fetch((Object.entries(documents.justificatifs)[index][1].url), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-forwarded-authorization': token,
        },
      });

      if (!response.ok) {
        throw new Error('La réponse du réseau n\'est pas correcte');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${(Object.entries(documents.justificatifs)[index][1].name)}`; // Définissez le nom du fichier ici
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    catch (error) {
      console.error(`Échec du téléchargement pour ${(Object.entries(documents.justificatifs)[index][1].name)}`, error);
    }
  };

  const DownloadDocuments = async (index) => {
    try {
      const response = await fetch((Object.entries(documents.documents)[index][1].url), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-forwarded-authorization': token,
        },
      });

      if (!response.ok) {
        throw new Error('La réponse du réseau n\'est pas correcte');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${(Object.entries(documents.documents)[index][1].name)}`; // Définissez le nom du fichier ici
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    catch (error) {
      console.error(`Échec du téléchargement pour ${(Object.entries(documents.documents)[index][1].name)}`, error);
    }
  };

  const DownLoadZipDocuments = () => {
    const zip = new JSZip();
    const folder = zip.folder();
    //Add files to folder
    {
      Object.keys(documents.documents).forEach((e) => {
        const blob = fetch(documents.documents[e].url,
          {
            method: 'POST',
            headers: { 'x-forwarded-authorization': token }
          }).then((res) => res.blob());
        folder.file(documents.documents[e].name, blob, { binary: true });
      });
    }
    //Zip folder and download folderZip
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "ZipFiles");
    });
  };

    const jsonObject = JSON.parse(documents.donnees)

    const documentErreur = documents.erreurs

    const documentErreurLine = documentErreur?.split("\n")

  return (
      <>
          <div className="box-liasse-tunnel">
              <div className="bloc-titre-tunnel">
                  <h2>
                      <b>Documents déposés dans le tunnel</b>
                  </h2>
                  <div className="depose-doc-tunnel">
                      {Object.keys(documents.justificatifs).length ? (
                          <div className="list-documents">
                              {Object.keys(documents.justificatifs).map((e, index) => (
                                  <p className="download-list" key={index}>
                                      <button className="button-link-doc" onClick={() => DownloadJustificatif(index)}>
                                          <span className="title-justificatif">{e}</span>
                                          <img src={downloadicon} alt=""/>
                                      </button>
                                  </p>
                              ))}{" "}
                          </div>
                      ) : (
                          <p className="bloc-text">Pas de doc déposé dans le tunnel.</p>
                      )}
                      {(Object.keys(documents.justificatifs).length) ?
                          <button className="button-PDF" onClick={() => DownLoadZipJustificatifs()}>
                              <img
                                  style={{
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                  }}
                                  src={iconeBouttonDoc}
                                  alt="Télécharger les docs"
                              />
                              Télécharger tous les docs
                          </button> : <></>
                      }
                  </div>
              </div>
              <div className="bloc-titre-liassePDF">
                  <h2>
                      <b>Liasse PDF générée</b>
                  </h2>
                  <div className="depose-doc-liasse-PDF">
                      {(Object.keys(documents.documents).length) ? (
                          <div className="liasse-document">
                              {Object.keys(documents.documents).map((e, index) => (
                                  <p className="download-list" key={index}>
                                      <button className="button-link-doc" onClick={() => DownloadDocuments(index)}>
                                          <img src={reddownloadicon} alt=""/>
                                          <span className="title-document">{e}</span>
                                      </button>
                                  </p>
                              ))}{" "}
                          </div>
                      ) : (
                          <p className="bloc-text">Pas de liasse généré.</p>
                      )}
                      {Object.keys(documents.documents).length ? (
                          <button className="button-PDF" onClick={() => DownLoadZipDocuments()}>
                              <img
                                  style={{
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                  }}
                                  src={iconeBouttonDoc}
                                  alt=""
                              />
                              Télécharger la liasse
                          </button>
                      ) : (
                          <></>
                      )}
                  </div>
              </div>
          </div>
          <div className="bloc-titre">
              <h2>
                  <b>Log d’erreur en cours :</b>
              </h2>
              {(!!documentErreurLine &&  Object.keys(documentErreurLine).length) ? (
                  <div className="depose-doc">
                      {documentErreurLine?.map((numero, erreur) =>
                          <p className="bloc-text" key={erreur}>{numero}</p>
                      )}
                  </div>
              ) : (
                  <div className="depose-doc">
                      <p className="bloc-text">Pas d’erreur en cours</p>
                  </div>
              )}
          </div>
          <div className="bloc-titre">
              <h2>
                  <b>Log d’erreur historique :</b>
              </h2>
              <div className="depose-doc">
              {Object.keys(documents.erreursHisto).length > 0 ? (
                  Object.keys(documents.erreursHisto).map(key => {
                      const formattedPhrase = documents.erreursHisto[key].replace(/\\n/g, "\n");
                      return (
                          <div key={key}>
                              <pre className="bloc-text"><b>{key}</b> : {formattedPhrase}</pre>
                          </div>
                      );
                  })
              ) : (
                  <p className="bloc-text">Aucune donnée disponible.</p>
                  )}
              </div>
          </div>
          <div className="bloc-titre">
              <h2>
                  <b>Log d’erreur Universign :</b>
              </h2>
              <div className="depose-doc">
                  <p className="bloc-text">(a definir)</p>
              </div>
          </div>
          <div className="bloc-titre">
              <h2>
                  <b>Données Tunnel</b>
              </h2>
              <div className="depose-doc">
                  <pre className="bloc-text-donnees-tunnnel">{JSON.stringify(jsonObject, null, 2)}</pre>
              </div>
          </div>
      </>
  );
}
